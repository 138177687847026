<template>
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
      <div class="container-fluid">
        <router-link to="/" class="navbar-brand fs-1 ms-3 fw-semibold" id="brand-color">TreZi-Fix</router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTZX" aria-controls="navbarTZX" aria-expanded="false" aria-label="Toggle navbarTZX">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarTZX">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
            <router-link to="/" class="nav-link fs-5 me-3">Home</router-link>
            </li>
            <li class="nav-item">
            <router-link to="/Anwendung" class="nav-link fs-5 me-3">Anwendung</router-link>
            </li>
            <li class="nav-item">
                <router-link to="/Produkte" class="nav-link fs-5 me-3">Produkte</router-link>
            </li>
          </ul>
          <button type="button" class="btn btn-warning me-3" data-bs-toggle="modal" data-bs-target="#contactModal">Kontakt</button>
        </div>
      </div>
    </nav>
    <div class="modal fade" id="contactModal" tabindex="-1" aria-labelledby="contactModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="contactModalLabel">Kontakt</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body text-start">
                    <h6>FTS Fair Trade + Service KG - TreZi-Fix</h6>
                    <h>Erich Feilner</h><br>
                    <h>Gleißenberg 31</h><br>
                    <h>DE-96152 Burghaslach</h><br><br>
                    <h>Fon: +49 / (0) 9552 / 92 11 - 93</h><br>
                    <h>Fax: +49 / (0) 9552 / 92 11 - 94</h><br>
                    <h>E-Mail:<a href="mailto:ftskg@trezi-fix.com" class="text-decoration-underline">ftskg@trezi-fix.com</a></h>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'headerTZX'
    }
</script>

<style scoped>
    #brand-color{
        color: #0f8abd;
        background: none;
    }



nav a.router-link-exact-active {
  color: #0f8bda

}
</style>