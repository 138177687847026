import { createRouter, createWebHistory } from 'vue-router'
import home from '../views/Home.vue'
import anwendung from '../views/Anwendung.vue'
import produkte from '../views/Produkte.vue'
import agb from '../views/AGB.vue'
import dsgvo from '../views/DSGVO.vue'
import impressum from '../views/Impressum.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: home,
    meta: { title: 'Home'}
  },
  {
    path: '/Anwendung',
    name: 'anwendung',
    component: anwendung,
    meta: { title: 'Anwendung'}
  },
  {
    path: '/Produkte',
    name: 'produkte',
    component: produkte,
    meta: { title: 'Produkte'}
  },
  {
    path: '/AGB',
    name: 'agb',
    component: agb,
    meta: { title: 'Allg. Geschäftsbedingungen'}
  },
  {
    path: '/Datenschutzerklaerung',
    name: 'dsgvo',
    component: dsgvo,
    meta: { title: 'Datenschutzerklärung'}
  },
  {
    path: '/Impressum',
    name: 'impressum',
    component: impressum,
    meta: { title: 'Impressum'}
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) =>{
  const companyName = 'TreZi-Fix';
  document.title = `${companyName} - ${to.meta.title || 'Page'}`;
  next();
});

export default router
