<template>
  <div id="app" class="d-flex flex-column min-vh-100">
    <headerTZX/>
    <main class="flex-grow-1">
      <router-view/>
    </main>
  </div>
  <footerTZX/>
</template>

<script>
  import headerTZX from './components/headerTZX.vue';
  import footerTZX from './components/footerTZX.vue';

  export default{
    name: 'app',
    components: {
      headerTZX,
      footerTZX
    }
  }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>
