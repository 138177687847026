<template>
  <div class="container">
    <div class="text-start mt-5">
        <h3>Produkte</h3>
        <hr>
    </div>
    <div class="row">
      <!-- Loop over products and render each ProductCard -->
      <div class="col-12 col-sm-6 col-md-3 mb-4 d-flex" v-for="product in products" :key="product.name">
        <ProductCard :product="product" />
      </div>
    </div>
  </div>
</template>


<script>
import ProductCard from '@/components/productCardTZX.vue';

export default {
  components: {
    ProductCard
  },
  data() {
    return {
      products: [
        {
          name: 'Gewindeschale',
          imageUrl: '/images/tzx_gs_ty.png',
          sizes: [
            { size: 'M6', itemNumber: '44 1516 06', minDiameter: 16, recDiameter: 18, newton: 11.500, vpe: 100 },
            { size: 'M8', itemNumber: '44 2025 08', minDiameter: 18, recDiameter: 20, newton: 14.000, vpe: 100 },
            { size: 'M10', itemNumber: '44 2825 10', minDiameter: 30, recDiameter:30, newton: 31.000, vpe: 100 },
            { size: 'M12', itemNumber: '44 5025 12', minDiameter: 32, recDiameter: 36, newton: 45.000, vpe: 100 },
            { size: 'M20', itemNumber: '44 5035 20', minDiameter: 40, recDiameter: 40, newton: 100.050, vpe: 50 }
          ]
        },
        {
          name: 'Gewinderolle',
          imageUrl: '/images/tzx_gr_ty.png',
          sizes: [
            { size: 'M6', itemNumber: '44 1516 07', minDiameter: 18, recDiameter: 18, newton: 14, vpe: 100 },
            { size: 'M8', itemNumber: '44 2025 09', minDiameter: 18, recDiameter: 20, newton: 28.8, vpe: 100 },
            { size: 'M10', itemNumber: '44 2835 10', minDiameter: 30, recDiameter: 30, newton: 41, vpe: 100 }
          ]
        },
        {
          name: 'Montageschlüssel',
          imageUrl: '/images/tzx_mtg_ty.png',
          sizes: [
            { size: 'M6', itemNumber: '44 0017', vpe: 1 },
            { size: 'M8', itemNumber: '44 0010', vpe: 1 },
            { size: 'M10', itemNumber: '44 002', vpe: 1 },
            { size: 'M12', itemNumber: '44 001', vpe: 1 },
            { size: 'M20', itemNumber: '44 000', vpe: 1 }
          ]
        },
        {
          name: 'Ringschlüssel',
          imageUrl: '/images/tzx_rs_ty.png',
          sizes: [
            { size: 'SW 7', itemNumber: '44 0014', vpe: 1 },
            { size: 'SW 9', itemNumber: '44 004', vpe: 1 },
            { size: 'SW 12', itemNumber: '44 003', vpe: 1 }
          ]
        }
      ]
    };
  }
};
</script>

<style scoped>

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.col-12, .col-sm-6, .col-md-3 {
  display: flex;
  justify-content: center; 
  flex: 1;
}

.card {
  width: 100%;  
  display: flex;
  flex-direction: column;
}

.card-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between; 
}

.card-img-top {
  object-fit: cover;
  height: 200px;
}


</style>
