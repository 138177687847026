<template>
  <div class="container text-start">
    <h2>Datenschutzerklärung</h2>
    <br />
    <h5>Google Analytics</h5>
    <h
      >Diese Website benutzt Google Analytics, einen Webanalysedienst der Google
      Inc. („Google“). Google Analytics verwendet sog. „Cookies“, Textdateien,
      die auf Ihrem Computer gespeichert werden und die eine Analyse der
      Benutzung der Website durch Sie ermöglichen. Die durch den Cookie
      erzeugten Informationen über Ihre Benutzung dieser Website werden in der
      Regel an einen Server von Google in den USA übertragen und dort
      gespeichert.<br /></h
    ><br /><h
      >Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten
      und auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse)
      an Google sowie die Verarbeitung dieser Daten durch Google verhindern,
      indem Sie ein für Ihren Browser verfügbares Browser-Plugin herunterladen
      und installieren: <br /></h
    ><a
      href="https://tools.google.com/dlpage/gaoptout?hl=de“>http://tools.google.com/dlpage/gaoptout?hl=de"
      class="text-decoration-underline"
      >Datenerfassung durch Google Analytics unterbinden</a
    >
    <h5><br />Webfonts</h5>
    <h
      >Auf diesen Internetseiten werden externe Schriften, Google Fonts
      verwendet. Google Fonts ist ein Dienst der Google Inc. ("Google"). Die
      Einbindung dieser Web Fonts erfolgt durch einen Serveraufruf, in der Regel
      ein Server von Google in den USA. Hierdurch wird an den Server
      übermittelt, welche unserer Internetseiten Sie besucht haben. Auch wird
      die IP-Adresse des Browsers des Endgerätes des Besuchers dieser
      Internetseiten von Google gespeichert. Nähere Informationen finden Sie in
      den Datenschutzhinweisen von Google, die Sie hier abrufen können:</h
    ><br /><a
      href="https://fonts.google.com/about"
      class="text-decoration-underline"
      >https://fonts.google.com/about</a
    ><br /><a href="https://rsms.me/inter/" class="text-decoration-underline"
      >https://rsms.me/inter/</a
    >
  </div>
</template>
