<template>
  <div class="container mt-5">
    <div class="d-flex flex-column align-items-center">
      <!-- Main Title -->
      <img src="../assets/logo.png" class="img-fluid tzx-logo-home" draggable="false" oncontextmenu="return false;">
    </div>

    <!-- Lines extending from the text -->
    <div class="row my-4">
      <div class="col d-flex flex-column align-items-center">
        <span class="text-tzx-primary">Beste Verbindung</span>
        <div class="line-vertical"></div>
      </div>
      <div class="col d-flex flex-column align-items-center">
        <span class="text-tzx-primary">Anwendungsbeispiele</span>
        <div class="line-vertical"></div>
      </div>
      <div class="col d-flex flex-column align-items-center">
        <span class="text-tzx-primary">stanzen & formen</span>
        <div class="line-vertical"></div>
      </div>
      <div class="col d-flex flex-column align-items-center">
        <span class="text-tzx-primary">Bestellen & Sparen</span>
        <div class="line-vertical"></div>
      </div>
    </div>

    <!-- Bottom Links -->
    <div class="row">
      <div class="col">
        <p class="text-tzx-primary">Über uns</p>
      </div>
      <div class="col">
        <p class="text-tzx-primary">Ihr Anspruch</p>
      </div>
      <div class="col">
        <p class="text-tzx-primary">Sicherheit</p>
      </div>
    </div>

    <!-- Welcome Text -->
    <div class="mt-5 text-start">
      <h3 class="fw-bold">Willkommen!</h3>
      <p>
        Lange haben wir darüber nachgedacht, wie wir Ihnen unsere Produkte, Dienstleistungen und das dahinter
        stehende Unternehmen vorstellen sollen. Letztendlich sind wir zu dem Entschluss gekommen, dies genauso
        transparent, umfassend und offen zu tun wie unsere Zusammenarbeit sein soll. Wir freuen uns auf Sie!
      </p>
    </div>
  </div>
  <div class="container-fluid bg-light mt-custom text-start app_info">
      <div class="ms-3 p-3">
        <h3 class="fs-2 fw-bold text-tzx-primary home-info">Anwendung</h3>
        <hr>
        <p class="home-text-app">TreZi-Fix - Gewindeschalen sorgen für sichere Verbindungen. Beim Bau von Sichtdachstühlen genauso wie im Innenausbau. Deshalb gibt es TreZi-Fix Gewindeschalen für alle Anwendungsfälle in den Größen M6, M8, M10, M12 & M20. Mit dem patentierten TreZi-Fix - Montageschlüssel erreichen Sie auch in schwierigen Situationen die Stellen, an denen Sie eine sichere Verbindung einbringen wollen ...</p>
        <router-link to="/Anwendung" class="text-decoration-none link-app p-1 fs-info-btn">Schauen Sie sich hier die Anwendungsbeispiele an</router-link>
      </div>
    </div>
    <div class="container-fluid bg-light mt-custom text-start app_info">
      <div class="ms-3">
      <h3 class="fs-2 fw-bold text-tzx-primary home-info">Produkte</h3>
      <hr>
      <div class="row mb-5 home-products">
        <div class="col mb-cus" style="height: 20rem">
          <div class="card" style="width: 20rem;">
            <img src="../assets/products/tzx_gs_ty.png" class="card-img-top img-fluid mx-auto" oncontextmenu="return false;" draggable="false">
            <div class="card-body" style="height: 200px;">
              <h5 class="card-title">Gewindeschale</h5>
              <p>Unsere Gewewindeschale ist in verschiedenen Größen verfügbar.</p>
              <p>Informationen wie Bohrungsdurchmesser, Zugkraft und VPE finden Sie auf unserer Produktseite.</p>
            </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">Größen: M6, M8, M10, M12 und M20</li>
              </ul>
          </div>
        </div>
        <br>
        <br>
        <div class="col mb-5">
          <div class="card" style="width: 20rem;">
            <img src="../assets/products/tzx_gr_ty.png" class="card-img-top img-fluid mx-auto" oncontextmenu="return false;" draggable="false">
            <div class="card-body" style="height: 200px;">
              <h5 class="card-title">Gewinderolle</h5>
              <p>Unsere Gewinderolle ist in verschiedenen Größen verfügbar.</p>
              <p>Informationen wie Bohrungsdurchmesser, Zugkraft und VPE finden Sie auf unserer Produktseite.</p>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">Größen: M6, M8 und M10</li>
            </ul>
          </div>
        </div>
        <div class="col mb-5">
          <div class="card" style="width: 20rem;">
            <img src="../assets/products/tzx_mtg_ty.png" class="card-img-top img-fluid mx-auto" oncontextmenu="return false;" draggable="false">
            <div class="card-body" style="height: 200px;">
              <h5 class="card-title">Gewindeschale</h5>
              <p>Unser Montageschlüssel ist in verschiedenen Größen für die Gewindeschale verfügbar.</p>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">Größen: M6, M8, M10, M12 und M20</li>
            </ul>
          </div>
        </div>
        <div class="col mb-5">
          <div class="card" style="width: 20rem;">
            <img src="../assets/products/tzx_rs_ty.png" class="card-img-top img-fluid mx-auto" oncontextmenu="return false;" draggable="false">
            <div class="card-body" style="height: 200px;">
              <h5 class="card-title">Ringschlüssel</h5>
              <p>Unser Ringschlüssel ist in verschiedenen Größen für die Montage der verschiedenen Gewinderollen verfügbar.</p>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">Größen: SW 7, SW 9 und SW 12</li>
            </ul>
          </div>
        </div>
      </div>
      <router-link to="/Produkte" class="text-decoration-none link-app p-2 fs-info-btn">Weitere Informationen zu den Produkten</router-link>
    </div>


  </div>
</template>

<style>
  .mt-custom{
    margin-top: 10%;
  }
  .home-info{
    letter-spacing: .06em;
  }
  .home-text-app{
    font-size: 24px;
  }
  .fs-info-btn{
    font-size: 18px;
  }

  .text-tzx-primary{
    color: #0f8abd
  }

  .home-products img{
    width: 50%;
  }
  .tzx-logo-home{
    width: 450px;
  }
  
  .link-app{
    color: rgb(47, 47, 47);
    background: rgb(255, 213, 0);
    border-radius: .5rem;
  }
  .text-tzx-primary{
    color: #0f8abd;
  }
  .line-vertical {
  width: 2px;
  height: 50px;
  background-color: #202225; /* Matches the Bootstrap "primary" color */
  margin-top: 10px;
}
@media only screen and (max-width: 430px) {
  .home-text-app{
    font-size: 16px;
  }
  .fs-info-btn{
    font-size: 14px;
  }
  .mb-cus{
    margin-bottom: 150px;
  }
}
</style>