<template>
  <div class="container text-start mt-5">
    <div class="container-tzx-app-info">
      <h4>Anwendung</h4>
      <hr />
      <br />
      <div class="d-flex align-items-center">
        <div class="col-md-6">
          <p>
            TreZi-Fix - Gewindeschalen sorgen für sichere Verbindungen. Beim Bau
            von Sichtdachstühlen genauso wie im Innenausbau. Deshalb gibt es
            TreZi-Fix Gewindeschalen für alle Anwendungsfälle in den Größen M6,
            M8, M10, M12 &amp; M20.
          </p>
        </div>
        <div class="col-md-auto col-tzx-one">
          <img src="../assets/TZX_STRENGTH.png" class="img-fluid col-img-one ms-3" alt="Placeholder Image" />
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="col-md-auto">
          <img src="../assets/TZX_WRENCH.png" class="img-fluid col-img-second" alt="Placeholder Image" />
        </div>
        <div class="col-md-6">
          <p>
            Mit dem patentierten TreZi-Fix - Montageschlüssel errechen Sie auch
            in schwierigen Situationen die Stellen , an denen Sie eine sichere
            Verbindung einbringen wollen. Von oben, von unten, von der Seite
            oder gar über Kopf: Präzise wird die Gewindeschale an der Stelle
            platziert, an der Sie eine Gewindestange einschrauben wollen.
          </p>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="col-md-6">
          <p>
            TreZi-Fix - Gewinderollen bewähren sich bestens, wenn es um
            verdeckte oder versenkbare Verbindungen geht. Die Gewinderolle lässt
            sich mit einem handelsüblichen gekröpften 12er- Ringschlüssel sicher
            festziehen. Gewinderollen gibt es in den Größen M6, M8 und M10.
          </p>
        </div>
        <div class="col-md-auto">
          <img src="../assets/TZX_PROD.png" class="img-fluid col-img-third" alt="Placeholder Image" />
        </div>
      </div>
    </div>
    <br /><br />
    <h3>Anwendungsbeispiele</h3>
    <hr />
    <div class=" container-tzx-usage-example">
      <br />
      <div class="row">
        <h4>Dachstuhl</h4>
        <div class="col-auto">
          <img src="../assets/TZX_DS.png" draggable="false" oncontextmenu="return false;" id="example-ds-img" />
        </div>
        <div class="col-md-6">
          <h><br />Gerade bei der bauseitigen Montage wirkt sich das TreZi-Fix -
            System besonders kostengünstig aus. Die Lage des Werkstückes und der
            Verbindungen sind vorgegeben und die Montage soll schnell erledigt
            werden. Wer schon einmal bei dem Versuch, über Kopf ein
            Befestigungsteil einzubringen, dieses verloren hat und sich dann ein
            neues holen musste, weiß die Vorteile des TreZi-Fix - System zu
            schätzen. <br /><br />
            Eine passgenaue Bohrung erreicht eine sichere Druckverteilung auf
            möglichst großer Fläche. Eine Scher- und Spreizwirkung wird
            weitreichend vermieden.</h>
        </div>
      </div>
      <br />
      <div class="row">
        <h4>Möbelbau</h4>
        <div class="col-auto">
          <img src="../assets/TZX_MB.png" draggable="false" oncontextmenu="return false;" id="example-mb-img" />
        </div>
        <div class="col-md-6">
          <h><br />TreZi-Fix bei der Montage vor Ort. Auf Maß gefertigte
            Einrichtungen lassen sich schnell und exat montieren. Hier wird der
            Vorteil einer sicheren Verbindung aus Metall gegenüber einer Lösung
            aus Kunststoff schnell sichtbar: Kein Ermüden der Verbindungsteile
            durch den laufenden Gebrauch! <br /><br />
            Mit TreZi-Fix wird die Verbindung von Arbeitsplattenstößen bei der
            Einbauküchenmontage zum Kinderspiel. <br /><br />
            Mit Nuten und loser Feder sowie dem TreZi-Fix - Montageschlüssel
            lassen sich die Verbindungen so exakt positionieren und festziehen,
            dass die Schnittkanten sauber schließen. Schon die Vorteile der
            Montage im Unterschrankbereich werden Ihre Monteure und Kunden
            begeistern.</h>
        </div>
      </div>
      <br />
      <div class="row">
        <h4>Treppenbau</h4>
        <div class="col-auto">
          <img src="../assets/TZX_TRPNBU.png" draggable="false" oncontextmenu="return false;" id="example-mb-img" />
        </div>
        <div class="col-md-6">
          <h><br />Das TreZi-Fix - Verbindungssystem ist schnell und im wahrsten
            Sinne des Wortes "Preis &amp; Wert". Je nach Einsatzgebiert lässt
            sich damit so manche Arbeitsstunde sparen - das wirkt sich positiv
            auf Ihre Kalkulation aus und stärkt ihre Wettbewerbsfähigkeit.
            <br />
            <br />
            TreZi-Fix - das ideale Verbindungssystem im Treppenbau. Verbindungen
            von Pfosten an Wangen, Wangen an Wangen und Pfosten an Pfosten:
            Einfach, schnell, sicher und kostengünstig gelöst.
          </h>
        </div>
      </div>
      <br />
      <h5>Der besondere Vorteil im Laden- und Messebau:</h5>
      <hr>
      <div class="row">
        <div class="col-md-10">
          <h class="text-body-secondary">Soll eine Verbindung wieder gelöst werden, so funktioniert dies genauso unproblematisch wie die Montage. Einfach mit dem speziellen Montageschlüssel die Gewindeschalen nach dem Lösen wieder herausnehmen. Wiederverwendung und Wiederaufbau ganz leicht gemacht.</h>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>
.container-tzx-usage-example img {
  width: 50%;
}

.col-img-one {
  width: 50%;
}

.col-img-second {
  width: 50%;
}

.col-img-third {
  width: 50%;
}

.container-tzx-usage-example img{
  width: 450px;
}

@media only screen and (max-width: 1500px){
  .container-tzx-usage-example img{
    width: 300px;
  }
  .col-tzx-one{
    width: 700px;
  }
  .col-img-one{
    width: 65%;
  }
}

@media only screen and (max-width:430px) {
  .container-tzx-app-info {
    font-size: 11px
  }

  .col-img-one {
    width: 100%;
  }

  .col-img-second {
    width: 100%;
    margin-right: 100px;
  }
  .col-img-third{
    width: 100%;
  }
  .container-tzx-usage-example{
    font-size: 13px;
  }

  .container-tzx-usage-example img{
    width: 100vw;
  }

}
</style>