<template>
  <footer class="bg-dark text-white py-4">
    <div class="container">
      <div class="row text-center">
        <div class="col-sm"><router-link to="/AGB" class="text-decoration-none text-light me-3">Allg. Geschäftsbedingungen</router-link></div>
        <div class="col-sm"><router-link to="/Datenschutzerklaerung" class="text-decoration-none text-light me-3">Datenschutzerklärung</router-link></div>
        <div class="col-sm"><router-link to="/Impressum" class="text-decoration-none text-light me-3">Impressum</router-link></div>
      </div>
      <div class="row mt-3">
        <div class="col text-center">
          <hr>
          <small>&copy; {{ currentYear }} FTS Fair Trade + Service KG - TreZi-Fix</small>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
    export default{
        name: 'footerTZX',
        data(){
            return{
                currentYear: new Date().getFullYear(),
            }
        }
    }
</script>

<style scoped>
    footer{
        position: relative;
        bottom: 0;
        width: 100%;
        margin-top: 5vh;
    }

    @media only screen and (max-width: 415px) { 
      .footer-links{
        font-size:8px;
        align-items: center;
      }
    }
</style>