<template>
  <div class="card mb-4 h-100">
    <div class="card-img-container">
      <img :src="product.imageUrl" alt="Product image" class="card-img-top" />
    </div>
    <div class="card-body d-flex flex-column justify-content-between">
      <h4 class="card-title">{{ product.name }}</h4>
      <div class="product-details mt-3">
        <p><strong>Artikelnummer:</strong> {{ selectedSize.itemNumber }}</p>
        <p v-if="product.name === 'Gewindeschale' || product.name === 'Gewinderolle'">
          <strong>Bohrung Ø mm mindestens:</strong> {{ selectedSize.minDiameter }} mm
        </p>
        <p v-if="product.name === 'Gewindeschale' || product.name === 'Gewinderolle'">
          <strong>Bohrung Ø mm empfohlen:</strong> {{ selectedSize.recDiameter }} mm
        </p>
        <p v-if="product.name === 'Gewindeschale' || product.name === 'Gewinderolle'">
          <strong>Zugkraft max. kN:</strong> {{ selectedSize.newton }} kN
        </p>
        <p><strong>VPE:</strong> {{ selectedSize.vpe }}</p>
      </div>
      <div class="form-group">
        <label for="sizeSelect" class="fw-bold fs-4">Größe</label>
        <select v-model="selectedSize" id="sizeSelect" class="form-control">
          <option v-for="size in product.sizes" :key="size.size" :value="size">
            {{ size.size }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: Object
  },
  data() {
    return {
      selectedSize: this.product.sizes[0] 
    };
  },
  watch: {
    product(newProduct) {
      this.selectedSize = newProduct.sizes[0];
    }
  }
};
</script>

<style scoped>
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.card-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px; 
}

.card-img-top {
  width: 100%;
  height: 100%;
  object-fit: contain; 
}

.product-details {
  margin-top: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.select-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media only screen and (max-width: 430px) {
  .product-details{
    font-size: 12px;
  }
}

</style>
